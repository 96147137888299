.lfr-ddm-form-container .icon-angle-left {
    font-size: inherit;
}

.liferay-ddm-form-field-paragraph {
    // font-size: 14px
    font-size: 0.875rem;
}

.liferay-ddm-form-field-tip {
    // font-size: 11px;
    font-size: 0.75rem;
}
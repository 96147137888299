//page .p-{className}
.p- {
    &index{
        h2{
            font-size: $font-20;
            font-weight: bold;
            padding-bottom: 3px;
        }
    }
}

.c- {
    &card {
        display: block;
        //   box-shadow: 0 1rem 3rem rgba($black, 0.175);
        //   border-radius: 10px;
        overflow: hidden;

        &-link {
            &:hover {
                .c-card-head-aspect-ratio .c-card-head-img {
                    transform: translate(-50%, -50%) scale(1.1);
                }
            }
        }

        &-layout {
            display: flex;
            flex-direction: column;
        }

        &-head {
            width: 100%;
            position: relative;
            overflow: hidden;
            background-color: $white;

            &-aspect-ratio {
                &::before {
                    content: "";
                    // 實際照片的高/寬
                    // padding-top: 66.75%;
                    padding-top: 52%;
                    display: block;
                }

                .c-card-head-img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &-img {
                width: 100%;
                transition: transform 0.3s ease-in-out;
            }
        }

        &-body {
            background-color: $white;

            // &__height-adjust {
            //     @include min-md {
            //         min-height: 329px;
            //     }

            //     @include min-lg {
            //         min-height: 265px;
            //     }

            //     @include min-xl {
            //         min-height: 241px;
            //     }
            // }

            &-container {
                padding: 0.5rem;
                background-color: $green;

                &:hover {
                    background-color: $green-02;
                    transition: background-color 0.3s ease-in-out;
                }
            }

            &-layout {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
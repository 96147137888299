.portlet-journal-content .journal-content-article img,
.portlet-journal-content .journal-content-article .cke_widget_image {
    max-width: 100%;
}

.portlet-journal-content .journal-content-article img[style*="float: left;"],
.portlet-journal-content .journal-content-article img[style*="float:left;"],
.portlet-journal-content .journal-content-article .cke_widget_image[style*="float: left;"],
.portlet-journal-content .journal-content-article .cke_widget_image[style*="float:left;"] {
    margin-right: 24px;
}

.portlet-journal-content .journal-content-article img[style*="float: right;"],
.portlet-journal-content .journal-content-article img[style*="float:right;"],
.portlet-journal-content .journal-content-article .cke_widget_image[style*="float: right;"],
.portlet-journal-content .journal-content-article .cke_widget_image[style*="float:right;"] {
    margin-left: 24px;
}

.portlet-journal-content .journal-content-article img[style*="float: left;"],
.portlet-journal-content .journal-content-article img[style*="float:left;"],
.portlet-journal-content .journal-content-article img[style*="float: right;"],
.portlet-journal-content .journal-content-article img[style*="float:right;"],
.portlet-journal-content .journal-content-article .cke_widget_image[style*="float: left;"],
.portlet-journal-content .journal-content-article .cke_widget_image[style*="float:left;"],
.portlet-journal-content .journal-content-article .cke_widget_image[style*="float: right;"],
.portlet-journal-content .journal-content-article .cke_widget_image[style*="float:right;"] {
    margin-bottom: 24px;
    margin-top: 24px;
}

.portlet-journal-content .portlet .portlet-header {
    flex-wrap: nowrap;
}

.portlet-journal-content .portlet .portlet-header .visible-interaction {
    display: block;
    visibility: hidden;
}

.portlet-journal-content .portlet .portlet-header .visible-interaction .lfr-icon-menu {
    background-color: #FFF;
    border: solid 2px;
    border-radius: 4px;
    color: #869cad;
}

.portlet-journal-content .portlet .portlet-header .visible-interaction .lfr-icon-menu:hover {
    border-color: #4ab6ed;
}

.portlet-journal-content .portlet .portlet-header .visible-interaction .lfr-icon-menu>a {
    color: inherit;
}

@media only screen and (min-width:768px) {
    .portlet-journal-content .portlet .portlet-header .visible-interaction {
        display: block;
        visibility: hidden
    };
}

body:not(.controls-hidden) .portlet-journal-content:hover .portlet .portlet-header .visible-interaction,
body:not(.controls-hidden) .portlet-journal-content:focus .portlet .portlet-header .visible-interaction,
body:not(.controls-hidden) .portlet-journal-content .portlet.open .portlet-header .visible-interaction {
    visibility: visible;
}

.portlet-journal-content.portlet-barebone .portlet .portlet-header {
    margin-bottom: 0;
    position: absolute;
    right: 1px;
}

.product-menu .sidebar-header {
    line-height: 32px;
    padding-bottom: 10px;
    padding-right: 9px;
    padding-top: 12px;
}

@media only screen and (min-width:768px) {
    .product-menu .sidebar-header {
        padding-bottom: 16px;
        padding-left: 24px;
        padding-top: 16px
    };
}

.product-menu .sidebar-header .sidenav-close {
    float: right;
    margin-right: -2px;
}

.product-menu .sidebar-header-logo {
    margin-right: 10px;
}

.product-menu .sidebar-body {
    bottom: 0;
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 55px;
}

@media only screen and (min-width:768px) {
    .product-menu .sidebar-body {
        top: 64px
    };
}

@media only screen and (max-width:320px) {
    .product-menu .sidebar-body {
        overflow: visible;
        position: static
    };
}

.product-menu .user-icon {
    // font-size: 14px;
    font-size: 0.875rem;
    height: 32px;
    line-height: 32px;
    width: 32px;
}

.product-menu .lfr-product-menu-sidebar .panel-group {
    overflow: hidden;
}

.product-menu small {
    display: block;
}

.product-menu .collapse-icon:before {
    right: 5px;
    top: auto;
}

.product-menu .list-group>.collapse>.list-group-item>.tabular-list-group,
.product-menu .list-group>.collapsing>.list-group-item>.tabular-list-group {
    margin-bottom: -10px;
    margin-top: -10px;
}

.product-menu .list-group-heading h1,
.product-menu .list-group-heading h2,
.product-menu .list-group-heading h3,
.product-menu .list-group-heading h4,
.product-menu .list-group-heading h5,
.product-menu .list-group-heading h6 {
    margin-bottom: 0;
    margin-top: 0;
}

.product-menu .list-group-item {
    border-top-width: 0;
    padding: 10px 20px;
}

.product-menu .lfr-icon-menu .btn-default {
    border-width: 0;
    height: 32px;
    line-height: 32px;
    padding: 0;
    width: 32px;
}

.product-menu .collapse-icon .panel-notifications-count {
    display: none;
}

.product-menu .collapse-icon.collapsed .panel-notifications-count {
    display: inline-block;
    font-weight: bold;
    min-width: 32px;
    padding: 0 5px;
    position: absolute;
    right: 46px;
    text-align: center;
    width: auto;
}

.product-menu .nav-equal-height-heading .collapse-icon:after,
.product-menu .nav-equal-height-heading .collapse-icon.collapsed:after {
    content: '';
    display: block;
    height: 12px;
    left: -6px;
    position: absolute;
    right: auto;
    top: 14px;
    width: 9px;
}

.product-menu .nav-equal-height-heading .collapse-icon:before {
    left: -8px;
    right: auto;
    z-index: 1;
}

.product-menu .nav-nested-margins>li .nav>li .nav>li {
    margin-bottom: 1px;
}

.product-menu.popover {
    background-color: #FFF;
}

.product-menu .impersonation-message {
    // font-size: 12px;
    font-size: 0.75rem;
}

.product-menu .manage-sites-link {
    padding: 1em;
}

.product-menu .site-selector {
    box-shadow: none;
}

.product-menu .site-selector .selectable-site {
    color: #333;
    height: auto;
}

.product-menu .site-selector .list-group-item-content,
.product-menu .site-selector .list-group-item-field {
    vertical-align: middle;
}

.product-menu .site-selector .site-link {
    color: #333;
}

.product-menu .site-selector .site-link.selected-site {
    color: #999;
}

.product-menu .site-selector .site-logo-container {
    padding: 2em;
}

.product-menu .staging-links {
    // font-size: 12px;
    font-size: 0.75rem;
    margin: 10px 22px 4px 16px;
}

.product-menu li.tree-node .tree-node-content.lfr-root-node {
    display: none;
}

.product-menu .layout-tree-options {
    position: absolute;
    right: 0;
    top: 4px;
}

.product-menu .tree-node-content .layout-tree-options {
    opacity: 0;
}

.product-menu .tree-node-content .layout-tree-options:focus {
    opacity: 1;
}

.product-menu .tree-node-content .layout-tree-options[data-deleteable="false"][data-parentable="false"][data-updateable="false"] {
    display: none;
}

.product-menu .tree-node-content.tree-node-selected .tree-label>.layout-tree-options,
.product-menu .tree-node-content:active .tree-label>.layout-tree-options,
.product-menu .tree-node-content.active .tree-label>.layout-tree-options,
.product-menu .tree-node-content:hover .tree-label>.layout-tree-options {
    opacity: 1;
}

.product-menu .tree-node-content .layout-tree:focus~.layout-tree-options,
.product-menu .tree-node-content .layout-tree-options:hover {
    opacity: 1;
}

.product-menu .tree-node-content .layout-tree-add[data-parentable="false"],
.product-menu .tree-node-content .layout-tree-delete[data-deleteable="false"],
.product-menu .tree-node-content .layout-tree-edit[data-updateable="false"] {
    display: none;
}

.product-menu .layout-set-tabs {
    display: table;
    font-size: 1em;
    padding: 0 0 2em 0;
    width: 100%;
}

.product-menu .layout-set-tabs .layout-set-tab {
    display: table-cell;
    margin-right: 10px;
    padding-bottom: 4px;
    position: relative;
}

.product-menu .layout-set-tabs .layout-set-tab .layout-set-link {
    display: inline-block;
    max-width: 87px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    padding-top: 8px;
}

.product-menu .layout-set-tabs .layout-set-tab .layout-set-link:hover {
    text-decoration: none;
}

.product-menu .layout-set-tabs .layout-set-tab:not(.selected-layout-set) {
    margin-right: 0;
    padding-left: 10px;
    width: 97px;
}

.product-menu .layout-set-tabs .layout-set-tab:not(.selected-layout-set):first-child {
    padding-left: 0;
}

.product-menu .layout-set-tabs .selected-layout-set {
    border-bottom: 1px solid;
}

.product-menu .layout-set-tabs .selected-layout-set .layout-set-link {
    max-width: 108px;
}

.toast-animation {
    display: inline-block;
    height: 20px;
    margin: 10px 4px 0 4px;
    position: relative;
    width: 20px;
}

.toast-animation .pm {
    border-left: 1px solid #FFF;
    border-radius: 1px;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
    -moz-transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .6s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition-delay: .2s;
    -moz-transition-delay: .2s;
    transition-delay: .2s;
}

.toast-animation .cn {
    border: 1px solid #FFF;
    border-radius: 1px;
    content: '';
    height: 18px;
    position: absolute;
    right: 0;
    top: -1px;
    -webkit-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -moz-transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .3s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition-delay: .2s;
    -moz-transition-delay: .2s;
    transition-delay: .2s;
    width: 15px;
}

body.open .toast-animation .pm {
    border-left-width: 6px;
    height: 18px;
    left: 4px;
    top: -1px;
}

body.open .toast-animation .cn {
    border-left-width: 9px;
    width: 19px;
}
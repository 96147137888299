@each $style, $value in $list-style-theme {
  .u-list-style--#{$style} {
    list-style: $value;
    padding-left: 40px;
  }
}

@each $style, $value in $list-style-position-theme {
  .u-list-style-position--#{$style} {
    list-style-position: $value;
  }
}

.c- {
    &listGroup {
        border-radius: 0px;

        .list-group {
            &-item {
                background-color: transparent;
                padding: 0;
                border: 0px;

                a {
                    color: $black;
                    background-color: $gray-100;
                    font-weight: bold;
                    display: block;
                }

                &:hover,&.active {
                    a {
                        color: $white;
                        background-color: $green;
                    }
                }
            }

            &-link {
                padding: .75rem 1rem;
            }
        }
    }
}
// 請謹慎修改
.u-link {
  &--underline {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
}

@each $color, $value in $color-theme {
  .u-link-#{$color} {
    color: $value;
    &:hover {
      color: $value;
    }
    &.u-link__hover--darken-10 {
      &:hover {
        color: darken($value, 10%);
      }
    }
    &.u-link__hover--lighten-10 {
      &:hover {
        color: lighten($value, 10%);
      }
    }
  }
}

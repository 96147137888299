.helper-reset {
    font-size: 100%;
}

.fieldset-legend {
    font-size: 1.2em;
}

.field-hint {
    font-size: .9em;
}

.buttonitem-content {
    font-size: 1em;
}

.buttonitem-icon-only {
    font-size: 0;
}

.has-error .alloy-editor-container .help-block {
    // font-size: 16px;
    font-size: 1rem;
}

.lfr-fullscreen-source-editor .lfr-fullscreen-source-editor-header .layout-selector span {
    // font-size: 12px;
    font-size: 0.75rem;
}

.form-search .input-group.advanced-search .toggle-advanced .icon-search {
    // font-size: 14px;
    font-size: 0.875rem;
}

@media(max-width:979px) {
    .input-flex-add-on .input-group-addon {
        // font-size: 18px;
        font-size: 1.125rem;
    }
}

.lfr-ddm-link-to-page-modal .lfr-ddm-pages-container .loading-icon {
    // font-size: 48px;
    font-size: 3rem;
}

.lfr-ddm-link-to-page-modal .lfr-ddm-pages-container.nav>li .loading-icon {
    // font-size: 18px;
    font-size: 1.125rem;
}

.lfr-item-viewer .lfr-item-viewer-close {
    // font-size: 16px;
    font-size: 1rem;
}

.lfr-item-viewer-icon-info-link {
    // font-size: 20px;
    font-size: 1.25rem;
}


.lfr-preview-file-image-overlay .modal-header .close {
    // font-size: 30px;
    font-size: 1.875rem;
}

.lfr-upload-container .upload-target .or-text {
    font-size: .8em;
}

.lfr-upload-container .upload-file .icon-file {
    // font-size: 40px;
    font-size: 2.5rem;
}

.app-view-entry .entry-title {
    font-size: 1.15em;
}

.app-view-entry .entry-title .entry-categories,
.app-view-entry a.entry-title .entry-tags {
    // font-size: 16px;
    font-size: 1rem;
}

.app-view-entry-taglib.entry-display-style.display-icon .entry-title {
    font-size: 1em;
}

.drop-here-info {
    // font-size: 25px;
    font-size: 1.5625rem;
}

.drop-here-info .drop-icons span:nth-of-type(3) {
    // font-size: 45px;
    font-size: 2.8125rem;
}

.drop-here-info .drop-text {
    // font-size: 20px;
    font-size: 1.25rem;
}

@media(max-width:979px) {
    .lfr-menu-list.dropdown-menu>li>a {
        // font-size: 22px;
        font-size: 1.375rem;
    }
}

.taglib-image-selector .progress-wrapper p {
    // font-size: 18px;
    font-size: 1.125rem;
}

.taglib-image-selector .progress-wrapper p.size {
    // font-size: 14px;
    font-size: 0.875rem;
}

.taglib-image-selector .browse-image-controls .drag-drop-label,
.taglib-image-selector .browse-image-controls .browse-image {
    // font-size: 16px;
    font-size: 1rem;
}

.input-localized .palette-items-container {
    font-size: 0;
}

.taglib-move-boxes .sortable-container .move-option .handle {
    font-size: 1.5em;
}

.taglib-move-boxes .sortable-container .move-option .title {
    font-size: 1.2em;
}

@media(max-width:979px) {

    .taglib-ratings.like .thumbrating a,
    .taglib-ratings.thumbs .thumbrating a {
        font-size: 1.5em
    }
}

.user-info .user-details .date-info {
    // font-size: 11px;
    font-size: 0.6875rem;
}

.user-info .user-details .user-name {
    // font-size: 14px;
    font-size: 0.875rem;
}

.user-status-tooltip .user-status-info .user-status-date {
    // font-size: 11px;
    font-size: 0.6875rem;
}

.lfr-card-modified-by-text,
.lfr-card-subtitle-text,
.lfr-card-workflow-text {
    // font-size: 13px;
    font-size: 0.8125rem;
}

.lfr-card-title-text {
    // font-size: 16px;
    font-size: 1rem;
}
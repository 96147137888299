.icon-large:before {
    font-size: 1.333333333em;
}

.icon-2x {
    font-size: 2em;
}

.icon-3x {
    font-size: 3em;
}

.icon-4x {
    font-size: 4em;
}

.icon-5x {
    font-size: 5em;
}

.icon-stack .icon-stack-base {
    font-size: 2em;
}

h1 {
    font-size: 2em;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
}
code,
kbd,
pre,
samp {
    font-size: 1em;
}
html {
    // font-size: 10px;
    font-size: 0.625rem;
}

body {
    // font-size: 14px;
    font-size: 0.875rem;
}

input,
button,
select,
textarea {
    font-size: inherit;
}

h1 small,
h1 .small,
.h1 small,
.h1 .small,
h2 small,
h2 .small,
.h2 small,
.h2 .small,
h3 small,
h3 .small,
.h3 small,
.h3 .small {
    font-size: 65%;
}

h4 small,
h4 .small,
.h4 small,
.h4 .small,
h5 small,
h5 .small,
.h5 small,
.h5 .small,
h6 small,
h6 .small,
.h6 small,
.h6 .small {
    font-size: 75%;
}

h1,
.h1 {
    // font-size: 36px;
    font-size: 2rem;
}

h2,
.h2 {
    // font-size: 30px;
    font-size: 1.875rem;
}

h3,
.h3 {
    //font-size: 24px;
    font-size: 1.5rem;
}

h4,
.h4 {
    //font-size: 18px;
    font-size: 1.125rem;
}

h5,
.h5 {
    //font-size: 14px;
    font-size: 0.875rem;
}

h6,
.h6 {
    // font-size: 12px;
    font-size: 0.75rem;
}

.lead {
    // font-size: 16px;
    font-size: 1rem;
}

@media(min-width:768px) {
    .lead {
        // font-size: 21px;
        font-size: 1.3125rem;
    }
}

small,
.small {
    font-size: 85%;
}

.initialism {
    font-size: 90%;
}

blockquote {
    // font-size: 17.5px;
    font-size: 1.09375rem;
}

blockquote footer,
blockquote small,
blockquote .small {
    font-size: 80%;
}

code {
    font-size: 90%;
}

kbd {
    font-size: 90%;
}

kbd kbd {
    font-size: 100%;
}

pre {
    // font-size: 13px;
    font-size: 0.8125rem;
}

pre code {
    font-size: inherit;
}

legend {;
    // font-size: 21px;
    font-size: 1.3125rem;
}

output {
    //font-size: 14px;
    font-size: 0.875rem;
}

.form-control {
    //font-size: 14px;
    font-size: 0.875rem;
}

.input-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn {
    // font-size: 12px;
    font-size: 0.75rem;
}

.form-group-sm .form-control {
    // font-size: 12px;
    font-size: 0.75rem;
}
.form-group-sm .form-control-static {
    // font-size: 12px;
    font-size: 0.75rem;
}

.input-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn {
    // font-size: 18px;
    font-size: 1.125rem;
}

.form-group-lg .form-control {
    // font-size: 18px;
    font-size: 1.125rem;
}

.form-group-lg .form-control-static {
    // font-size: 18px;
    font-size: 1.125rem;
}

@media(min-width:768px) {
    .form-horizontal .form-group-lg .control-label {
        // font-size: 18px;
        font-size: 1.125rem;
    }
}

@media(min-width:768px) {
    .form-horizontal .form-group-sm .control-label {
        // font-size: 12px;
        font-size: 0.75rem;
    }
}

.btn {
    //font-size: 14px;
    font-size: 0.875rem;
}


.btn-primary:active,
.btn-primary.active,
.open>.btn-primary.dropdown-toggle {
    background-image: none;
}


.btn-lg,
.btn-group-lg>.btn {
    // font-size: 18px;
    font-size: 1.125rem;
}

.btn-sm,
.btn-group-sm>.btn {
    // font-size: 12px;
    font-size: 0.75rem;
}

.btn-xs,
.btn-group-xs>.btn {
    // font-size: 12px;
    font-size: 0.75rem;
}

.dropdown-menu {
    //font-size: 14px;
    font-size: 0.875rem;
}

.dropdown-header {
    // font-size: 12px;
    font-size: 0.75rem;
}

.input-group-addon {
    //font-size: 14px;
    font-size: 0.875rem;
}

.input-group-addon.input-sm,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.input-group-addon.btn {
    // font-size: 12px;
    font-size: 0.75rem;
}

.input-group-addon.input-lg,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.input-group-addon.btn {
    // font-size: 18px;
    font-size: 1.125rem;
}

.input-group-btn {
    font-size: 0;
}

.navbar-brand {
    // font-size: 18px;
    font-size: 1.125rem;
}
.pagination-lg>li>a,
.pagination-lg>li>span {
    // font-size: 18px;
    font-size: 1.125rem;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
    // font-size: 12px;
    font-size: 0.75rem;
}

.label {
    font-size: 75%;
}

.badge {
    // font-size: 12px;
    font-size: 0.75rem;
}

.jumbotron p {
    // font-size: 21px;
    font-size: 1.3125rem;
}

@media screen and (min-width:768px) {
    .jumbotron h1,
    .jumbotron .h1 {
        // font-size: 63px
        font-size: 3.9375rem;
    }
}

.progress-bar {
    // font-size: 12px;
    font-size: 0.75rem;
}

.panel-title {
    // font-size: 16px;
    font-size: 1rem;
}

.close,
.modal-primary-action-button {
    // font-size: 21px;
    font-size: 1.3125rem;
}

.tooltip {
    // font-size: 12px;
    font-size: 0.75rem;
}

.popover {
    //font-size: 14px;
    font-size: 0.875rem;
}

.popover-title {
    //font-size: 14px;
    font-size: 0.875rem;
}

.carousel-control {
    // font-size: 20px;
    font-size: 1.25rem;
}

@media screen and (min-width:768px) {

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-prev,
    .carousel-control .icon-next {
        // font-size: 30px
        font-size: 1.875rem;
    }
}


.badge {
    // font-size: 12px;
    font-size: 0.75rem;
}

.badge-lg {
    // font-size: 16px;
    font-size: 1rem;
}

.badge-sm {
    // font-size: 9px;
    font-size: 0.5625rem;
}


.dropdown-menu .link-list .link-list-heading {
    color: #777;
    // font-size: 12px;
    font-size: 0.75rem;
    padding: 3px 20px;
}

@media(max-width:768px) {
    .form-control {
        // font-size: 16px;
        font-size: 1rem;
    }
}

.label {
    // font-size: 12px;
    font-size: 0.75rem;
}

.label-lg {
    // font-size: 16px;
    font-size: 1rem;
}

.label-sm {
    // font-size: 9px;
    font-size: 0.5625rem;
}

.detailed-list-view>.active .detailed-list-view-icon:after {
    // font-size: 16px;
    font-size: 1rem;
}

.detailed-list-view-icon [class^="icon-"],
.detailed-list-view-icon [class*=" icon-"] {
    // font-size: 28px;
    font-size: 1.75rem;
}

@media(min-width:768px) {

    .detailed-list-view-icon [class^="icon-"],
    .detailed-list-view-icon [class*=" icon-"] {
        // font-size: 38px
        font-size: 2.375rem;
    }
}

.list-group-card .list-group-card-icon {
    font-size: 2.4em;
}

@media(min-width:768px) {
    .list-group-card .list-group-card-icon {
        font-size: 3em;
    }
}

.list-group-card-checkbox-hidden-xs .checkbox input[type="checkbox"]:checked+.card .list-group-card-icon:after {
    // font-size: 16px;
    font-size: 1rem;
}

.loading-icon,
.loading-animation,
.loadingmask-message .loadingmask-message-content {
    // font-size: 16px;
    font-size: 1rem;
}

.loading-icon:after,
.loading-animation:after,
.loading-icon:before,
.loading-animation:before,
.loadingmask-message .loadingmask-message-content:after,
.loadingmask-message .loadingmask-message-content:before {
    font-size: inherit;
}

a.btn>.loading-icon,
a.btn>.loading-animation,
button>.loading-icon,
button>.loading-animation {
    font-size: 1em;
    margin-right: .8em;
    margin-top: 0;
}
.loading-icon.loading-icon-xs,
.loading-icon-xs.loading-animation,
.loading-animation.loading-animation-xs {
    // font-size: 8px;
    font-size: 0.5rem;
}

.loading-icon.loading-icon-sm,
.loading-icon-sm.loading-animation,
.loading-animation.loading-animation-sm {
    // font-size: 10px;
    font-size: 0.625rem;
}

.loading-icon.loading-icon-md,
.loading-icon-md.loading-animation,
.loading-animation.loading-animation-md {
    // font-size: 32px;
    font-size: 2rem;
}

.loading-icon.loading-icon-lg,
.loading-icon-lg.loading-animation,
.loading-animation.loading-animation-lg {
    // font-size: 64px;
    font-size: 4rem;
}

.pagination-lg.pagination-items-per-page a,
.pagination-lg.pagination-results,
.pagination-lg .pagination-items-per-page a,
.pagination-lg .pagination-results {
    // font-size: 18px;
    font-size: 1.125rem;
}

.pagination-lg.pagination>li>a,
.pagination-lg.pagination>li>span,
.pagination-lg .pagination>li>a,
.pagination-lg .pagination>li>span {
    // font-size: 18px;
    font-size: 1.125rem;
}

.pagination-sm.pagination-items-per-page a,
.pagination-sm.pagination-results,
.pagination-sm .pagination-items-per-page a,
.pagination-sm .pagination-results {
    // font-size: 12px;
    font-size: 0.75rem;
}

.pagination-sm.pagination>li>a,
.pagination-sm.pagination>li>span,
.pagination-sm .pagination>li>a,
.pagination-sm .pagination>li>span {
    // font-size: 12px;
    font-size: 0.75rem;
}

.panel-title {
    font-size: 1em;
}

.progress-xs .progress-bar {
    // font-size: 8px;
    font-size: 0.5rem;
}

.progress-lg .progress-bar {
    // font-size: 14px;
    font-size: 0.875rem;
}

.progress-xl .progress-bar {
    // font-size: 18px;
    font-size: 1.125rem;
}

.sidebar-body {
    // font-size: 14px;
    font-size: 0.875rem;
}

.sticker {
    // font-size: 12px;
    font-size: 0.75rem;
}

.sticker-sm {
    // font-size: 9px;
    font-size: 0.5625rem;
}

.sticker-lg {
    // font-size: 16px;
    font-size: 1rem;
}

.toggle-card-icon {
    // font-size: 16px;
    font-size: 1rem;
}

.toggle-switch-bar .toggle-switch-icon {
    // font-size: 12px;
    font-size: 0.75rem;
}

.toggle-switch-bar .button-icon {
    // font-size: 12px;
    font-size: 0.75rem;
}

input.toggle-switch {
    font-size: 62.5%;
}


input.toggle-switch:empty~.toggle-switch-bar {
    font-size: 1.2rem;
}

.toggle-switch-text {
    font-size: 1.25rem;
}

.icon-angle-left {
    font-size: 1.5em;
}

.icon-xs {
    font-size: .6em;
}

.icon-xs.icon-angle-left {
    font-size: .9em;
}

.icon-sm {
    font-size: .8em;
}

.icon-sm.icon-angle-left {
    font-size: 1.2em;
}

.icon-lg {
    font-size: 1.667em;
}

.icon-lg.icon-angle-left {
    font-size: 2.667em;
}

.icon-xl {
    font-size: 3.334em;
}

.icon-xl.icon-angle-left {
    font-size: 4.667em;
}

.icon-xxl {
    font-size: 5em;
}

.blockquote-xs {
    // font-size: 12.25px;
    font-size: 0.7656rem;
}

.blockquote-sm {
    // font-size: 14px;
    font-size: 0.875rem;
}

.blockquote-lg {
    // font-size: 26.25px;
    font-size: 1.6406rem;
}

.user-icon {
    // font-size: 14px;
    font-size: 0.875rem;
}

.user-icon-xxl {
    // font-size: 37px;
    font-size: 2.3125rem;
}

.user-icon-xl {
    // font-size: 22px;
    font-size: 1.375rem;
}

.user-icon-lg {
    // font-size: 13px;
    font-size: 0.8125rem;
}

.user-icon-sm {
    // font-size: 9px;
    font-size: 0.5625rem;
}

.user-icon-xs {
    // font-size: 8px;
    font-size: 0.5rem;
}

@media(min-width:768px) {
    .user-icon-xxl {
        // font-size: 50px;
        font-size: 3.125rem;
    }

    .user-icon-xl {
        // font-size: 30px;
        font-size: 1.875rem;
    }

    .user-icon-lg {
        // font-size: 18px;
        font-size: 1.125rem;
    }

    .user-icon-sm {
        // font-size: 12px;
        font-size: 0.75rem;
    }

    .user-icon-xs {
        // font-size: 8px;
        font-size: 0.5rem;
    }
}
// 針對 html 標籤做基本設定
ul {
  list-style: none;
  padding-left: 0;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(rgb(0, 151, 252), 0.3);

  @include min-md {
    -webkit-tap-highlight-color: transparent;
  }
}

a {
  color: $blue;
  -webkit-tap-highlight-color: rgba(rgb(0, 151, 252), 0.3);

  @include min-md {
    -webkit-tap-highlight-color: transparent;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:active {
    // outline: 5px solid $orange;
    box-shadow: 0px 0px 5px 5px $orange;
  }
}

section {
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}

.accesskey {
  font-size: $font-20;
  padding-bottom: 8px;
  line-height: 0;
  text-decoration: none;
}

html,
body {
  height: 100%;
  // font-size: 16px;
  font-size: 1rem;
}

#page {
  overflow: hidden;
}

//修正aui檔案造成的跑版
.navbar {
  margin-bottom: 0;
  border: 0;
}

.navbar-nav {
  margin: 0;
}

.navbar-collapse {
  overflow-x: hidden;
  padding-right: 0;
  padding-left: 0;
  border-top: 0;
}

@include max-xl {
  .collapse:not(.show) {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 0;
  }

  .navbar-nav {
    margin-right: 0;
  }
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  -webkit-box-shadow: 0px 0px 5px 5px #ffa500;
  box-shadow: 0px 0px 5px 5px #ffa500;
}
#gotoContent {
  &.sr-only-focusable:active,
  &.sr-only-focusable:focus {
    display: inline-block;
    padding: 2rem;
    background-color: #008855;
    color: #fff;
    font-weight: bold;
    font-size: 1.25rem;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
  }
}
.c- {
    &list {
        padding-inline-start: 15px;
        &-title {
            font-weight: bold;
            margin-bottom: 2rem;
        }

        &-content {
            font-weight: normal;
            margin-bottom: .5rem;
            margin-top: .5rem;

            li {
                position: relative;
                margin-bottom: .5rem;
            }

            &-disc {
                li {
                    padding-left: 1rem;
                    &::before {
                        content: "";
                        width: 4px;
                        height: 4px;
                        border-radius: 100%;
                        background-color: $black;
                        position: absolute;
                        top: 0.7rem;
                        left: 0;
                    }
                }
            }
        }
    }
}
//layout .l-{className}
.l- {
	&footer {
		// margin-top: auto;
        &-logo {
            img {
				width: 284px;
                @include min-xl {
                    width: 550px;
                }
            }
        }
		hr{
			// opacity: 0;
			position: relative;
			&::before{
				content: "";
				border-bottom:5px solid $green;
				position: absolute;
				bottom: 0;
				left: -1rem;
				right: -1rem;
			}
		}
	}
}

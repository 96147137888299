//layout .l-{className}
.l- {
	&header {
		&-logo {
			a{
				height: 45px;
				display: inline-block;
			}
			img {
				height: 45px;
				@include max-sm {
					width: 100%;
				}
			}
		}

		&-menu {
			color: $green;

			@include min-xl {
				background-color: $green;
				color: $white;
			}

			a {
				color: $green;

				@include min-xl {
					color: $white;
				}
			}
		}

		hr {
			// opacity: 0;
			position: relative;

			&::before {
				content: "";
				border-bottom: 2px solid $green;
				position: absolute;
				bottom: 0;
				left: -1rem;
				right: -1rem;
			}
		}

		.navbar-collapse {
			@include max-xl {
				position: absolute;
				top: 94px;
				left: -1rem;
				right: -1rem;
				z-index: 1;
			}
		}

		.navbar-nav {
			@include max-xl {
				background-color: $gray-100;
			}
		}

		.nav-item {
			font-size: $font-18;
			font-weight: bold;
			border-bottom: 1px solid $gray-500;
			color: $black;
			min-width: 155px;
			text-align: center;

			&:last-child {
				border-bottom: 0px;
			}

			@include min-xl {
				border-bottom: 0px;
				color: $white;
			}

			a {
				color: $black;

				@include min-xl {
					color: $white;
				}
			}

			&:hover {
				background-color: $green-02;

				a {
					color: $white;
				}
			}
		}
	}
}
// 請勿修改
// .u-m-{間距} 或 .u-p-{間距}
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@each $prop, $abbrev in (margin: m, padding: p) {
			@each $size, $length in $spacings {
				.u-#{$abbrev}#{$infix}-#{$size} {
					#{$prop}: $length !important;
				}
				.u-#{$abbrev}t#{$infix}-#{$size},
				.u-#{$abbrev}y#{$infix}-#{$size} {
					#{$prop}-top: $length !important;
				}
				.u-#{$abbrev}r#{$infix}-#{$size},
				.u-#{$abbrev}x#{$infix}-#{$size} {
					#{$prop}-right: $length !important;
				}
				.u-#{$abbrev}b#{$infix}-#{$size},
				.u-#{$abbrev}y#{$infix}-#{$size} {
					#{$prop}-bottom: $length !important;
				}
				.u-#{$abbrev}l#{$infix}-#{$size},
				.u-#{$abbrev}x#{$infix}-#{$size} {
					#{$prop}-left: $length !important;
				}
			}
		}
	}
}

.loading-animation,
.loadingmask-message .loadingmask-message-content {
    // font-size: 64px;
    font-size: 4rem;
}

.loadingmask-content {
    background: #CCC;
}

.loadingmask-message {
    // font-size: 64px;
    font-size: 4rem;
}

@media(max-width:991px) {
    .responsive-table-horizontal:after {
        font-size: 0;
    }

    .responsive-table-horizontal thead:after {
        font-size: 0;
    }
}

@media(max-width:767px) {
    .dialog-with-footer .button-holder .btn-lg {
        // font-size: 12px;
        font-size: 0.75rem;
    }
}

.cke_panel_block .cke_panel_grouptitle {
    // font-size: 20px;
    font-size: 1.25rem;
}

.lfr-item-viewer .search-info .keywords {
    font-size: 1.4em;
}

.lfr-item-viewer .search-info .change-search-folder {
    font-size: .8em;
}

.label-entry {
    // font-size: 14px;
    font-size: 0.875rem;
}

.previous-level {
    // font-size: 21px;
    font-size: 1.3125rem;
}

.layout-customizable-controls {
    font-size: 1.5em;
}

.paginator-current-page-report,
.paginator-total {
    font-size: .9em;
}

.tag-cloud .tag-popularity-1 {
    font-size: 1em;
}

.tag-cloud .tag-popularity-2 {
    font-size: 1.3em;
}

.tag-cloud .tag-popularity-3 {
    font-size: 1.6em;
}

.tag-cloud .tag-popularity-4 {
    font-size: 1.9em;
}

.tag-cloud .tag-popularity-5 {
    font-size: 2.2em;
}

.tag-cloud .tag-popularity-6 {
    font-size: 2.5em;
}

.tree-node-content .tree-hitarea {
    // font-size: 10px;
    font-size: 0.625rem;
}
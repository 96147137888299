.lfr-simulation-panel .custom-devices label {
    // font-size: 12px;
    font-size: 0.75rem;
}

.lfr-device .lfr-device-size-status .lfr-device-size-status-content {
    // font-size: 11px;
    font-size: 0.6875rem;
}

.product-menu .nav-equal-height-heading .collapse-icon:before {
    // font-size: 15px;
    font-size: 0.9375rem;
}

.product-menu .tree-container .tree-container .tree-node-content:not(.tree-node-selected) .tree-node-hidden-hitarea:before {
    font-size: .7em;
}

.product-menu .nav a,
.product-menu .list-group-heading,
.product-menu .panel-title {
    // font-size: 16px;
    font-size: 1rem;
}

.product-menu .sidebar-header {
    // font-size: 20px;
    font-size: 1.25rem;
}

.product-menu .list-group-heading {
    // font-size: 16px;
    font-size: 1rem;
}

.control-menu-level-1-heading {
    // font-size: 19px;
    font-size: 1.1875rem;
}

.control-menu-level-2-heading {
    // font-size: 18px;
    font-size: 1.125rem;
}

.control-menu-nav {
    // font-size: 16px;
    font-size: 1rem;
}

.live-link,
.staging-link {
    // font-size: 14px;
    font-size: 0.875rem;
}

.my-sites-menu .site-type {
    font-size: .8em;
}
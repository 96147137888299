.c- {
    &title{
        &-h2{
            font-size: $font-20;
            font-weight: bold;
            position: relative;
            padding-left: 0.75rem;
            &::before{
                content: "";
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                left: 0;
                width: 3px;
                height: 20px;
                background-color: $green;
            }
        }
        &-h3{
            font-size: $font-16;
            font-weight: bold;
        }
    }
}
// 請謹慎修改
// .u-font-{尺寸}
@each $font, $value in $font-size-theme {
  .u-#{$font} {
    font-size: $value;
  }
}

//.u-text-{顏色}
@each $color, $value in $color-theme {
  .u-text-#{$color} {
    color: $value;
  }
}

// .u-line-height-{數字}
@each $line-height, $value in $line-height-theme {
  .u-line-height-#{$line-height} {
    line-height: $value;
  }
}

// .u-font-weight-{數字}
@each $font-weight, $value in $font-weight-theme {
  .u-font-weight-#{$font-weight} {
    font-weight: $value;
  }
}
